import {
  ALBERTA_LOCATION_ID,
  BC_LOCATION_ID,
  MANITOBA_LOCATION_ID,
  NEW_BRUNSWICK_LOCATION_ID,
  NEWFOUNDLAND_LOCATION_ID,
  NOVA_SCOTIA_LOCATION_ID,
  ONTARIO_LOCATION_ID,
  PRINCE_EDWARD_ISLAND_LOCATION_ID,
  QUEBEC_LOCATION_ID,
  SASKATCHEWAN_LOCATION_ID,
} from '@/constants/location'

// This list should include all provinces containing any location where ICO is
// activated. For instance, if ICO is only activated in Calgary, the value
// should be ['AB'], even though ICO is not active in all of Alberta. The order
// of provinces in the array decides their order in the UI, so it should be
// alphabetical.
export const ICO_PROVINCES = ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'ON', 'PE', 'QC', 'SK']
export const NON_ICO_PROVINCES: string[] = []

// This list should include all location IDs where ICO is activated. Any type of
// location ID is valid, including provinces and cities, as we compare against
// the user's whole location path. Once ICO is launched nationwide, this value
// can be set to [0], for "Canada".
export const ICO_LOCATION_ALLOWLIST = [
  ALBERTA_LOCATION_ID,
  BC_LOCATION_ID,
  MANITOBA_LOCATION_ID,
  NEW_BRUNSWICK_LOCATION_ID,
  NEWFOUNDLAND_LOCATION_ID,
  NOVA_SCOTIA_LOCATION_ID,
  ONTARIO_LOCATION_ID,
  PRINCE_EDWARD_ISLAND_LOCATION_ID,
  QUEBEC_LOCATION_ID,
  SASKATCHEWAN_LOCATION_ID,
]

// This list should indicate all provinces containing any location where Warranty is activated.
export const WARRANTY_LOCATION_ALLOWLIST = [
  ALBERTA_LOCATION_ID,
  MANITOBA_LOCATION_ID,
  NEWFOUNDLAND_LOCATION_ID,
  ONTARIO_LOCATION_ID,
  PRINCE_EDWARD_ISLAND_LOCATION_ID,
]

// This list should include all location IDs where VE is activated. Any type of
// location ID is valid, including provinces and cities, as we compare against
// the user's whole location path. Once VE is launched nationwide, this value
// can be set to [0], for "Canada".
export const VALUE_ESTIMATE_ALLOWLIST = [
  ALBERTA_LOCATION_ID,
  BC_LOCATION_ID,
  MANITOBA_LOCATION_ID,
  NEW_BRUNSWICK_LOCATION_ID,
  NEWFOUNDLAND_LOCATION_ID,
  NOVA_SCOTIA_LOCATION_ID,
  ONTARIO_LOCATION_ID,
  PRINCE_EDWARD_ISLAND_LOCATION_ID,
  QUEBEC_LOCATION_ID,
  SASKATCHEWAN_LOCATION_ID,
]
