import React from 'react'
import { Icon, IconProps } from '../components/Icon'
const SvgChevronRight: React.FC<IconProps> = (props) => {
  return (
    <Icon fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        clipRule="evenodd"
        d="M9.367 5.16a.5.5 0 10-.734.68l5.686 6.159-5.686 6.16a.5.5 0 10.734.678l5.997-6.497a.498.498 0 000-.682z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  )
}
export default SvgChevronRight
