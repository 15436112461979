import { FC } from 'react'

import { MaxLengthIndicatorContainer, MaxLengthIndicatorText } from './styled'

export type MaxLengthIndicatorProps = {
  /**
   * Unique identifier of component
   */
  id: string
  /**
   * Max length of the input
   */
  maxLength?: number
  /**
   * Value in the text input
   */
  value?: string | number
  children: React.ReactNode
}

/**
 * @description Wrapper for text input fields that indicates the current and max length of characters of the field.
 * Should be used for input and textarea fields
 */
export const MaxLengthIndicator: FC<MaxLengthIndicatorProps> = ({
  children,
  id,
  maxLength,
  value,
}) => {
  const currentLength = value?.toString().length || 0

  const maxLengthIndicatorText = `${currentLength}/${maxLength}`

  if (!maxLength || maxLength <= 0) {
    return <>{children}</>
  }

  return (
    <MaxLengthIndicatorContainer
      data-testid={`max-length-indicator-${id}`}
      id={`max-length-indicator-${id}`}
    >
      <MaxLengthIndicatorText>{maxLengthIndicatorText}</MaxLengthIndicatorText>
      {children}
    </MaxLengthIndicatorContainer>
  )
}
